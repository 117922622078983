import React,{useCallback} from 'react'
import {useState} from 'react'
import { useMutation } from '@apollo/client';
import { PUBLISH_VIDEO, PUBLISH_VIDEO_LIST } from '../../../../../../graphql-client/Mutation';
import MakeImg from '../../../../../../uploadToS3/MakeImg';
import ComponentUploadFileToS3 from '../../../../../../uploadToS3/ComponentUploadFileToS3';
import { uploadImageToS3 } from '../../../../../../uploadToS3/UploadImgToS3';
export default function FormUpdateVideo(props) {
    
    const [formState, setFormState] = useState({
        description: '',
        title:'',
        urlDocument:'',
        thumbnail:'',
        isLoading: false,
      });
      const [croppedImage, setCroppedImage] = useState(null);
    const [formListVideo,  setFormListVideo] = useState({
      thumbnail:'',
      isLoading: false,
      });
      const [publishTitleVideoList] = useMutation(PUBLISH_VIDEO_LIST,{
        variables:{
          id: props.videoListId,
          title: formState.title
        },
      })
      
      const [publishAboutVideoList] = useMutation(PUBLISH_VIDEO_LIST,{
        variables:{
          id: props.videoListId,
          about: formState.description
        },
      })
      const [publishThumbnailVideoList] = useMutation(PUBLISH_VIDEO_LIST,{
        variables:{
          id: props.videoListId,
          thumbnail: formListVideo.thumbnail
        },
      })
      const [publishTitleVideo] = useMutation(PUBLISH_VIDEO, {
        variables: {
          id: props.videoId,
          title: formState.title,
        },
      })
      
      const [publishDocumentVideo]= useMutation(PUBLISH_VIDEO,{
        variables:{
            id: props.videoId,
            document: formState.urlDocument
        },
      })
      const [publishDescriptionVideo]= useMutation(PUBLISH_VIDEO,{
        variables:{
            id: props.videoId,
            description: formState.description,
        }
      })
      
      const handleSubmitPublishThumbnailVideoList = async (e)=> {
        e.preventDefault();
        await uploadImageToS3(croppedImage, formListVideo, setFormListVideo)
        props.publishListVideoManager && publishThumbnailVideoList();
        setFormListVideo({...formListVideo,thumbnail:''});
        setCroppedImage(null);
        alert("Đã thay đổi thành công ấn 'Thoát' để quay lại.")
      }; 
      const handleSubmitPublishTitle = (e)=> {
        e.preventDefault();
        props.publishListVideoManager?publishTitleVideoList():publishTitleVideo();
        setFormState({...formState,title:''});
        alert("Đã thay đổi thành công ấn 'Thoát' để quay lại.")
      }; 

      const handleSubmitPublishDocument = async (e)=> {
        e.preventDefault();
        await publishDocumentVideo();
        setFormState({...formState,urlDocument:''});
        alert('Đã thêm tài liệu mới vào video thành công ấn nút "Thoát" để quay lại.');
      };

      const handleSubmitPublishDescription = (e)=> {
        e.preventDefault();
        props.publishListVideoManager?publishAboutVideoList():publishDescriptionVideo();
        setFormState({...formState,description:''});
        !props.publishVideoManager? alert('Đã sửa thông tin Khóa học thành công ấn nút "Thoát" để quay lại.'): 
        alert('đã sửa thông tin Video thành công ấn nút "Thoát" để quay lại.');
      };
      const handleTitleChange = useCallback((e) => {
        setFormState({
          ...formState,
          title: e.target.value
        });
      }, [formState]);
    return (
          <div 
          className="modal-content"
          >
              <div className="modal-header d-flex justify-content-between">
                <h5 className="modal-title" id="newVideoModalModalLabel">
                  {
                  props.publishListVideoManager
                  ?
                  "Chỉnh sửa khóa học của bạn."
                  :
                  'Chỉnh sửa video của bạn.'
                  }
                </h5>
                <i className="fa fa-times" aria-hidden="true" data-bs-dismiss="modal" ></i>
              </div>
              <div className="modal-body">    
                <div className='form'>
                    {
                    props.publishListVideoManager&&
                    (!formListVideo.thumbnail
                      &&
                    <div className="d-flex align-items-center me-2 mb-3">
                        <b className='me-2 mb-0'>Ảnh </b>
                        { formListVideo.isLoading ===true
                        ? <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div>
                        :<div className='d-flex flex-column'>
                            <MakeImg 
                            croppedImage={croppedImage} setCroppedImage={setCroppedImage}
                            >
                            </MakeImg>
                            {croppedImage && (
                              <button onClick={handleSubmitPublishThumbnailVideoList} type="button" className="btn btn-primary mx-auto">
                                Cập nhật
                              </button>
                            )}
                        </div>
                        
                        }
                    </div>)
                    }

                    <form onSubmit={handleSubmitPublishTitle}>    
                      <div className="mb-3">
                        <label htmlFor="titleNewVideo-from" className="col-form-label">
                          <b>Tiêu đề </b> 
                        </label>
                        <textarea 
                          id="titleNewVideo-from" 
                          className="form-control" 
                          rows="4" cols="50"  type="text"  
                          placeholder="Cập nhật tiêu đề video của bạn"
                          value={formState.title}
                          onChange={handleTitleChange} 
                        />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" 
                            data-bs-dismiss="modal">Thoát</button>
                            <button type="submit" className="btn btn-info" >Cập nhật</button>
                        </div>
                      </div>
                    </form>
                     
                    <hr />
                    <form  onSubmit={handleSubmitPublishDescription}>
                        <div className="mb-3">
                            <label htmlFor="descriptionNewVideo-from" className="col-form-label">
                              <b>Miêu tả </b> 
                            </label>
                            
                            <textarea  
                                    id="descriptionNewVideo-from" 
                                    className="form-control" 
                                    rows="4" cols="50"  type="text"  
                                    value={formState.description} 
                                    placeholder="Cập nhật miêu tả của video ... "
                                    onChange={
                                      (e) =>
                                      setFormState({
                                          ...formState,
                                          description: e.target.value
                                      })
                                    }
                            />
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" >Thoát</button>
                                <button type="submit" className="btn btn-info" >Cập nhật</button>
                            </div>
                        </div>
                    </form>
                    <hr />
                    {
                      !props.publishListVideoManager &&
                      <form  onSubmit={handleSubmitPublishDocument}>
                          <div className="mb-3">
                              <label htmlFor="docsNewVideo-from" className="col-form-label">
                                <b>Tài liệu</b> 
                              </label>
                              <ComponentUploadFileToS3 formState={formState} setFormState={setFormState}></ComponentUploadFileToS3>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-light" data-bs-dismiss="modal">Thoát</button>
                                  <button type="submit" className="btn btn-info" >Cập nhật</button>
                              </div>
                          </div>
                      </form>
                    } 
                </div>
              </div>
          </div>
    )
}
