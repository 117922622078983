import { useState } from 'react'
import React from 'react'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
export default function HeaderMid() {
  const [searchFilter, setSearchFilter] = useState('');
  const [isInputVisible, setInputVisible] = useState(false);
  const history = useHistory();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      history.push(`/videosSearch/${searchFilter}`);

    }
  };
  const toggleSearchInput = () => {
    setInputVisible(!isInputVisible);
  };
  return (
    <li className='nav-item flex-fill d-flex justify-content-center'>
      <form className="d-flex">
        <input 
          onChange={(e) => setSearchFilter(e.target.value)} 
          className={`form-control me-1 ${isInputVisible ? 'd-block' : 'd-none d-md-block'}`} 
          type="search" 
          placeholder="Tìm kiếm" 
          aria-label="Search"
          onKeyPress={handleKeyPress}
        />
        <Link 
          to={isInputVisible ? `/videosSearch/${searchFilter}` : '#'} 
          onClick={toggleSearchInput}
          className="btnAdd"
        >
          <i className="fas fa-search me-1" />
        </Link>
      </form>

      <style>{`
        @media (max-width: 768px) {
          .form-control {
            display: none;
          }
          .btnAdd {
            display: inline-block;
          }
        }
      }`}</style>

    </li>
  )
}
// import { useState, useEffect } from 'react';
// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

// export default function HeaderMid() {
//   const [searchFilter, setSearchFilter] = useState('');
//   const [isInputVisible, setInputVisible] = useState(false);
//   const [isListening, setIsListening] = useState(false);
//   const history = useHistory();
//   const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//   const recognition = new SpeechRecognition();

//   // Bật chế độ nghe liên tục và kết quả tạm thời nếu cần
//   recognition.continuous = false;
//   recognition.interimResults = false;

//   useEffect(() => {
//     recognition.onresult = (event) => {
//       const transcript = event.results[0][0].transcript;
//       setSearchFilter(transcript);
//       // Tùy chọn: Tự động thực hiện tìm kiếm sau khi nhận dạng giọng nói
//       history.push(`/videosSearch/${transcript}`);
//     };
    
//     recognition.onerror = (event) => {
//       console.error("Có lỗi xảy ra trong quá trình nhận dạng: ", event.error);
//     };

//     // Dọn dẹp khi component bị hủy
//     return () => {
//       recognition.onresult = null;
//       recognition.onerror = null;
//     };
//   }, [recognition, history]);

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();
//       history.push(`/videosSearch/${searchFilter}`);
//     }
//   };

//   const toggleSearchInput = () => {
//     setInputVisible(!isInputVisible);
//   };

//   const startListening = () => {
//     if (!isListening) {
//       recognition.start();
//       setIsListening(true);
//     } else {
//       recognition.stop();
//       setIsListening(false);
//     }
//   };

//   return (
//     <li className='nav-item flex-fill d-flex justify-content-center'>
//       <form className="d-flex">
//         <input
//           onChange={(e) => setSearchFilter(e.target.value)}
//           className={`form-control me-1 ${isInputVisible ? 'd-block' : 'd-none d-md-block'}`}
//           type="search"
//           placeholder="Tìm kiếm"
//           aria-label="Tìm kiếm"
//           value={searchFilter}
//           onKeyPress={handleKeyPress}
//         />
//         <Link
//           to={isInputVisible ? `/videosSearch/${searchFilter}` : '#'}
//           onClick={toggleSearchInput}
//           className="btnAdd"
//         >
//           <i className="fas fa-search me-1" />
//         </Link>
//         <button
//           type="button"
//           className="btnAdd"
//           onClick={startListening}
//         >
//           <i className={`fas fa-microphone ${isListening ? 'listening' : ''}`} />
//         </button>
//       </form>
//     </li>
//   );
// }