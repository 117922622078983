import React from 'react'
import { useQuery } from '@apollo/client';
import {USER_MANAGER_SUBSCRIBED_TO} from '../../graphql-client/Queries'
import CardSubUser from './CardSubUser';
import { UPDATE_SUBSCRIBED } from '../../graphql-client/notification/Notification';
import NotificationError from '../NotificationError';
export default function SubUserRight() {
    const [modalVisible] = React.useState(false)
    const {loading, error, data, subscribeToMore} = useQuery(USER_MANAGER_SUBSCRIBED_TO)
    let userManageId = data && data.userManage && data.userManage.id;
    React.useEffect(() => {
        let unsubscribe
        if (!modalVisible) {
            unsubscribe = subscribeToMore({
                document: UPDATE_SUBSCRIBED,
                variables: {userId : userManageId},
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const newUserManage = subscriptionData.data.newUserSubscription.subscriber ;
                    return Object.assign({}, prev, {
                        userManage: newUserManage,
                        }
                    );
                }
            });
        }
        if (unsubscribe) return () => unsubscribe()
    }, [modalVisible, subscribeToMore, userManageId])

    
    if(loading) return <p>loading...</p>;
    if(error) return <p>Error! {error.message}</p>;
    
    
    return (
        <div className="col-xl-10 col-lg-12 col-mb-12 col-sm-12 mx-auto ">
            <div className="p-0 marginVideoRecommend">
            <h5 className='ms-3 mt-1'>Kênh đã đăng ký</h5>
                {
                    data.userManage.subscribers.length ===0
                    ?<NotificationError text ={'Bạn chưa đăng ký kênh nào.'}></NotificationError>
                    :<div className="row ms-auto">
                        {
                        data.userManage.subscribers.map((UserSubscription)=>{
                            return (                                  
                                <CardSubUser
                                    key={UserSubscription.id}
                                    username={UserSubscription.subscribedTo.username}
                                    avatarUser={UserSubscription.subscribedTo.avatar}
                                    idUser={UserSubscription.subscribedTo.id}
                                    about = {UserSubscription.subscribedTo.about}
                                    intUserSub = {UserSubscription.subscribedTo.countSubscribedTo}
                                >
                                </CardSubUser>
                            )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}
