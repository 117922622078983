import React from 'react'
import NewComment from './commentFormVideo/NewComment'
import CommentVideo from './CommentsVideo'
import { useAuthContext } from '../../../../../../../context/AuthContext'
export default React.memo(function ListCommentVideoPlaying() {
    const {isAuthenticated} = useAuthContext()
    
    return (
        <div className="tab-pane" id="comments" role="tabpanel" aria-labelledby="comments-tab-comments">
            <div className="card card-body d-flex-column fa-border row col-12 m-1">
                {
                    !isAuthenticated 
                    ? 
                    <div className="alert alert-dark" role="alert">
                        Hãy đăng nhập hoặc đăng ký để có thể tham gia thảo luận.
                    </div> 
                    :
                    <NewComment parentCommentId={false} ></NewComment>
                }
                <CommentVideo></CommentVideo>
            </div>
        </div>
    )
}
)