import React from 'react';
import { uploadImageToS3 } from './UploadImgToS3';
import 'react-image-crop/dist/ReactCrop.css';

export default function ComponentUploadImgToS3({ formListVideo, setFormListVideo }) {

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const file = new File([selectedFile], 'image.jpg', { type: selectedFile.type });
      uploadImageToS3(file, formListVideo, setFormListVideo);
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <div className='p-2'>
      {!formListVideo.thumbnail ? (
        <div className="d-flex justify-content-between ">
          <input type="file" accept="image/*" onChange={handleFileSelect} multiple={false} />
        </div>
      ) : (
        formListVideo.thumbnail && (
          <div className="d-flex justify-content-start">
            <p className='me-3'>Đã tải ảnh lên</p>
            <img src={formListVideo.thumbnail} alt="uploaded" className="thumbnailOnForm" />
          </div>
        )
      )}
    </div>
  );
}
