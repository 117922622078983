import React from 'react'
import { useQuery } from '@apollo/client';
import { getDocumentVideo } from '../../../../../../graphql-client/Queries';
import { useParams } from 'react-router-dom';
import NotificationError from '../../../../../NotificationError';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
export default React.memo(function DocumentVideoPlaying() {
     let {id} = useParams();
     const {loading, error, data} = useQuery(getDocumentVideo,{
         variables: {id},
     });
     if(loading) return <p>loading...</p>;
     if (error) return <p>Error! {error.message}</p>;
    const pdfUrl = data.video.document;
    
    return (
        <div className="tab-pane documentVideo" id="document" role="tabpanel" aria-labelledby="document-tab-document">
            <div className='card card-body d-flex-column bd-highlight border-0 row col-12 m-1'>
                {
                    pdfUrl
                    ?
                    <div className='documentPdfVideo'>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={pdfUrl} />
                        </Worker>
                    </div>
                    :
                    <NotificationError text={"Video chưa có tài liệu nào."}></NotificationError>
                    
                }
            </div>
            
        </div>
    )
}
)