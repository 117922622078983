import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { getUser } from '../../../../graphql-client/Queries'
export default function ProfileUser() {
    let {idUser} = useParams()
    
    const{loading, error, data}= useQuery(getUser,{
        variables: {id:idUser},
    })
    if(loading) return <p>loading...</p>
    if(error) return <p>Error! {error.message}</p>
    
    return (
        <div >
            <br />
            <h5>
                Giới thiệu kênh 
            </h5>
            {
                data.user.about
                ?<p className="text_per_line p-2">
                    {data.user.about}
                </p>
                :<p>Chưa có thông tin.</p>
            }
            
            <hr />
            
            <h5>
                Video giới thiệu
            </h5>
            
            <video controls className="videoAbout mx-auto">
                <source src={data.user.videoAbout} type="video/mp4" />
                <source src={data.user.videoAbout} type="video/ogg" />
                Trình duyệt của bạn không hỗ trợ thẻ video.
            </video>
            <hr />
            <h5>
                Thông tin liên hệ
            </h5>
            {
            data.user.info
            ?<p className="text_per_line p-2">
                {data.user.info}
            </p>
            :<p>Chưa có thông tin liên hệ nào.</p>
            }
            
        
        </div>
    )
}
