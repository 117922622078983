import React, { useState } from 'react';
import { UploadFileToS3 } from './UploadFileToS3';
export default function ComponentUploadFileToS3({ formState, setFormState }) {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [process, setProcess] = useState(0);

    const handleUploadFile = () => {
        UploadFileToS3(file, formState, setFormState, setIsLoading, setProcess);
    };

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    };
    
    return (
        <div className='p-2'>
            {isLoading ? (
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: process + '%' }}
                        aria-valuenow={process} aria-valuemin={0} aria-valuemax={100}>
                        {process}%
                    </div>
                </div>
            ) : (
                !formState.urlDocument ? (
                    <div className="d-flex justify-content-between">
                        <input 
                            type="file" 
                            title='Tải lên tài liệu' 
                            accept=".pdf" // Chỉ cho phép các định dạng PDF, Word, và text
                            onChange={handleFileSelect} 
                        />
                        {file && (
                            <div className='pe-3'>
                                <button onClick={handleUploadFile} className=''>Tải lên</button>
                            </div>
                        )}
                    </div>
                ) : (
                    formState.urlDocument && (
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <div>
                                Đã tải lên tài liệu, hãy tiếp tục hoàn thiện chi tiết video
                            </div>
                        </div>
                    )
                )
            )}
        </div>
    );
}
