// import WatchingVideoLeftBot from './wVideo_left_bot/WVideoLeftBot'
// import React from 'react'
// export default React.memo(function WatchingVideoLeft(props) {
  
//     return (
//         <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto card  WatchingVideoLeft d-flex flex-column ">
            
//             <video  controls controlsList="nodownload" className="videoPlay  mx-auto mt-2 p-2" 
//             preload="auto" // hoặc preload="metadata"
//             autoPlay>
//               <source className="embed-responsive-item " src={props.urlVideo} type="video/mp4" />
//               <source className="embed-responsive-item " src={props.urlVideo} type="video/ogg" />
//             </video>
//             <WatchingVideoLeftBot></WatchingVideoLeftBot>
//         </div>
//     )
// }
// )
import WatchingVideoLeftBot from './wVideo_left_bot/WVideoLeftBot';
import React, { useEffect, useRef } from 'react';
import videojs from 'video.js'; // Import Video.js

export default React.memo(function WatchingVideoLeft(props) {
  const videoRef = useRef(null); // Ref cho thẻ video
  const playerRef = useRef(null); // Ref cho đối tượng Video.js player

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        playbackRates: [0.5, 1, 1.5, 2],
      });
      playerRef.current.src({
        src: props.urlVideo,
        type: 'video/mp4',
      });
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [props.urlVideo]);
  
  console.log(props.urlVideo);
  return (
    <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto card WatchingVideoLeft d-flex flex-column">
      {/* Video.js player */}
      <video
        id="my-video"
        className="video-js vjs-default-skin videoPlay mx-auto mt-2 p-2"
        ref={videoRef}
        // width="640"
        // height="360"
      >
        
      </video>

      {/* Component con */}
      <WatchingVideoLeftBot />
    </div>
  );
});
