import React from 'react'
import {useState} from 'react'
import { CREATE_VIDEO } from '../../../../../../graphql-client/Mutation';
import { useMutation } from '@apollo/client';
import {LIST_VIDEO_USER_MANAGE} from '../../../../../../graphql-client/Queries';
import ComponentUploadFileToS3 from '../../../../../../uploadToS3/ComponentUploadFileToS3';
export default React.memo(function FormTitleVideo(props) {
  
    const [formState, setFormState] = useState({
        description: '',
        title:'',
        document:'',
        tags:'',
        urlDocument:''
      });
      
     const [createVideo] = useMutation(
        CREATE_VIDEO, 
        {
          variables: {
            description: formState.description,
            url: props.urlVideo,
            title:formState.title,
            document:formState.urlDocument,
            thumbnail:props.thumbnail,
            videoListId:props.videoListId,
            tags: formState.tags
          },
          update: (cache, {data:{createVideo}})=>{
            cache.modify({
              query: LIST_VIDEO_USER_MANAGE,
              id: cache.identify({ __typename: 'VideoList', id: props.videoListId }),
              fields: {
                countVideo(existingCountVideoRefs, { readField }) {
                  return existingCountVideoRefs +1;
                },
                video(existingVideosRefs, { toReference }) {
                  const newVideoRef = toReference({
                    __typename: 'Video',
                    id: createVideo.id,
                  });
                  return [newVideoRef,...existingVideosRefs];
                },
              },
            });
            },
        }
      );
    
      const handleSubmit = async (e)=> {
        e.preventDefault();
        await createVideo();
        setFormState({...formState, description: '', title:'', document:'',tags:'', thumbnail:''});
        props.setUrl();
        alert('Đã thêm video mới thành công')
      };

    return (
      <div className="">
        <form onSubmit={handleSubmit}>   
            {/* <div className="modal-body"> */}
                    <div className="mb-3">
                      <label 
                      htmlFor="titleNewVideo-from"
                        className="col-form-label">
                        Tiêu đề vủa Video
                      </label>
                      <input 
                          className="form-control" id="titleNewVideo-from" rows="2" 
                        value={formState.title}
                            onChange={(e) =>
                            setFormState(
                              {
                                ...formState,
                                title: e.target.value
                              }
                            )
                          } 
                      />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="docsNewVideo-from" className="col-form-label">
                          Gắn thẻ video
                        </label>
                        <textarea 
                            id="docsNewVideo-from"  
                            value={formState.tags}
                            className="form-control"  
                            rows="2" cols="50" 
                            placeholder="Ví dụ: #toan8 #hoctoan #thaydaytoan"
                            onChange={
                              (e) =>
                              setFormState({
                                  ...formState,
                                  tags: e.target.value
                              })
                            } 
                        />
                    </div>
                              
                    <div className="mb-3">
                      <label htmlFor="descriptionNewVideo-from" className="col-form-label">
                        Miêu tả video
                      </label>
                      <textarea 
                          id="descriptionNewVideo-from" 
                          value={formState.description}
                          className="form-control"  
                          rows="4" cols="50" 
                          placeholder="Nhập vào mô tả video"
                        onChange={
                            (e) =>
                            setFormState({
                                ...formState,
                                description: e.target.value
                            })
                          }
                      />
                    </div>
                    <hr />
                    <div className="mb-3">
                        <label htmlFor="docsNewVideo-from" className="col-form-label">
                          Thêm tài liệu
                        </label>
                        <ComponentUploadFileToS3 formState={formState} setFormState={setFormState}></ComponentUploadFileToS3>
                    </div>
                    
            {/* </div>  */}
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Thoát</button>
              <button 
                  type="submit" 
                  disabled={!props.urlVideo} 
                  title={props.urlVideo ? 'Nhấn để gửi video' : 'Chưa hoàn tất tải lên video'}
                  className="btn-light"
                  data-bs-dismiss="modal"
              >
                  Xuất bản
              </button> {/* Vô hiệu hóa nút nếu urlVideo không hợp lệ */}
                  </div>
        </form>
      </div>    
    )
})
