import React from 'react'

export default function FeedBack() {
  return (
    <div className='mt-5 videosRecommended '>
        <div className="alert alert-dark" role="alert">
            <p className='ms-4'>Cảm ơn bạn đã quan tâm ủng hộ trang web <b>LinkLearn</b>.</p>
            <p className='ms-4'>
                LinkLearn cung cấp tài nguyên miễn phí giúp giáo viên, giảng viên, chuyên gia, tổ chức tạo
                kho lưu trữ và chia sẻ kiến thức, học liệu, bài giảng, khóa học trực tuyến. 
                Nhằm kiến tạo một cộng đồng ngày càng phát triển vững mạnh chúng tôi 
                không ngừng lắng nghe ý kiến để thay đổi và hoàn thiện ứng dụng ngày một hoàn thiện hơn.
            </p>
            <p className='ms-4'>Mọi ý kiến đóng góp xin gửi qua email: <u>linklearn.hotro@gmail.com</u>.</p>
        </div> 
    </div>
  )
}
