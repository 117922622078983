import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';

export default function MakeImg({ croppedImage, setCroppedImage }) {

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);

          canvas.toBlob((blob) => {
            const file = new File([blob], 'uploaded-image.jpg', { type: selectedFile.type });
            setCroppedImage(file);  // Đặt ảnh vào state croppedImage mà không cần cắt
          }, selectedFile.type);
        };
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <div className='p-1'>
      <div className="d-flex justify-content-between">
        <input type="file" title='chọn ảnh' accept="image/*" onChange={handleFileSelect} multiple={false} />
      </div>
      <div className='mx-auto'>
        {croppedImage &&
          <div className="d-flex justify-content-center">
            <img src={URL.createObjectURL(croppedImage)} alt="" className='max-withMobileImg' />
          </div>
        }
      </div>
    </div>
  );
}
