
export const uploadImageToS3 = async (file, formListVideo, setFormListVideo) => {
  if (!file) {
    return;
  }

  try {
    // Fetch presigned URL từ backend
    const response = await fetch(process.env.REACT_APP_URL_WEB_SERVER_ROUTER_UPLOADS3 + `?fileName=${file.name}&fileType=${file.type}`);
    
    // Kiểm tra xem phản hồi có hợp lệ không
    if (!response.ok) {
      const errorText = await response.text();  // Đọc nội dung lỗi để debug
      console.error('Error fetching presigned URL:', errorText);
      throw new Error('Failed to get presigned URL');
    }

    const { url } = await response.json();  // Lấy URL từ JSON

    // Tải file lên S3 bằng presigned URL
    const uploadResponse = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    // Kiểm tra phản hồi từ quá trình tải lên
    if (!uploadResponse.ok) {
      const uploadErrorText = await uploadResponse.text();  // Đọc nội dung lỗi để debug
      console.error('Error uploading image:', uploadErrorText);
      throw new Error('Failed to upload image');
    }

    // URL của ảnh trong S3 (bỏ phần query string)
    const imageUrl = url.split('?')[0];

    // Cập nhật trạng thái formListVideo
    setFormListVideo({
      ...formListVideo,
      thumbnail: imageUrl,
    });

    console.log('Image uploaded to:', imageUrl);
    return imageUrl;
  } catch (error) {
    console.error('Error uploading image: ', error);
  }
};
