import React from 'react'
import { useMutation, useQuery } from '@apollo/client';
import {USER_SUBSCRIPTION} from '../../../graphql-client/Mutation'
import {USER_MANAGER_SUBSCRIBED_TO} from '../../../graphql-client/Queries'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthContext';

export default function ButtonSubUser() {
    const {isAuthenticated} = useAuthContext()
    const {idUser}= useParams();
    const [created_user_sub] = useMutation(USER_SUBSCRIPTION,{
        variables: {userId : idUser},
    });
    const {data, loading: loadingListUserSub,error:errorListUserSub} = useQuery(USER_MANAGER_SUBSCRIBED_TO)
    if(loadingListUserSub) return 'loading'
    if(errorListUserSub) return <button className="btn-btn-line mx-auto">Đăng nhập để đăng ký kênh</button>
    
    if(!isAuthenticated)
    return <button className="btn-btn-line mx-auto">Đăng nhập để theo dõi</button>
    
    const checkSub = data.userManage.subscribers.filter(e=>e.subscribedTo.id ===  idUser)
    
    const handleSubscribedToUser =()=>{
        created_user_sub();
      };
      
    return (
        <div className='mx-auto'>
            {
                <button  onClick={()=>handleSubscribedToUser()}  className="btnSubUser mx-auto">
                    {
                        checkSub.length === 0
                        ?<b>Đăng ký kênh</b> 
                        :<b>Bỏ đăng ký</b>
                    }
                </button>
            }
        </div>
        
    )
}
