import React, {useEffect} from 'react'
import NextVideo from './nextVideo/NextVideo'
import WatchingVideoLeft from './watchingVideo_left/WatchingVideoLeft.jsx'
import { useParams } from "react-router-dom";
import {  useMutation, useQuery } from '@apollo/client';
import { URL_VIDEO_PLAYING } from '../../graphql-client/Queries';
import {CREATED_VIEW} from '../../graphql-client/Mutation'
export default function WatchingVideo() {
    let {id} = useParams();
    const {loading, error, data}=useQuery(URL_VIDEO_PLAYING,{
        variables: {id},
    });

    const [createView] = useMutation(CREATED_VIEW,{
      variables:{
        videoId: id
      }
    })

    useEffect(() => {
      createView()
    }, [])
    

    if(loading) return <p>loading...</p>;
    if (error) return <p>Error! {error.message}</p>;
  return (
    <div className=' flex-column'>
        <div className='row'>
            <WatchingVideoLeft urlVideo={data.video.url}></WatchingVideoLeft>
            <NextVideo videoListId={data.video.videoListId}></NextVideo>
        </div>
    </div>
  )
}

