import React from 'react'
import {useState} from 'react'
import FormTitleVideo from './formTitleVideo/FormTitleVideo';
import ComponentUploadImgToS3 from '../../../../../uploadToS3/ComponentUploadImgToS3';
import ComponentUploadVideoToS3 from '../../../../../uploadToS3/ComponentUploadVideoToS3';

export default function FormNewVideo(props) {
  
    const [formState, setFormState] = useState({
          url: '',
          thumbnail:'',
          description: '',
          title:'',
          document:'',
          
      });
      const setUrl =()=>{
        setFormState({url:'', thumbnail:''})
       }
    
    return (
            <div className="modal-content">
              <div className="modal-header  d-flex justify-content-between">
                <h5 className="modal-title" id="newVideoModalModalLabel">
                  Tải lên video mới của bạn
                </h5>
                <i className="fa fa-times" aria-hidden="true" 
                  onClick={()=>setFormState({
                    url: '',
                    thumbnail:'',
                    description: '',
                    title:'',
                    document:'',
                  })} 
                  data-bs-dismiss="modal"
                ></i>
              </div>
              <div className="modal-body">    
                  <div className="form-group">
                    <label className="col-form-label">Chọn ảnh thu nhỏ của video</label>
                    <ComponentUploadImgToS3 formListVideo={formState} setFormListVideo={setFormState}></ComponentUploadImgToS3>
                    <label className="col-form-label">Tải lên video</label>
                    <ComponentUploadVideoToS3 formState={formState} setFormState={setFormState}></ComponentUploadVideoToS3>
                  </div>
                  <FormTitleVideo 
                    urlVideo={formState.url} 
                    thumbnail={formState.thumbnail} 
                    videoListId={props.videoListId}
                    setUrl ={setUrl}
                    >
                  </FormTitleVideo>
              </div>
            </div>
          
    )
}
