import jwtDecoded from 'jwt-decode'; // Thư viện dùng để giải mã token JWT

// Hàm quản lý JWT token
const JWTManager = () => {
    const LOGOUT_EVENT_NAME = 'jwt-logout'; // Tên sự kiện khi logout
    let inMemoryToken = ''; // Token sẽ được lưu trong bộ nhớ
    let refreshTokenTimeoutId = null; // ID của timeout để làm mới token
    let userId = null; // Lưu trữ userId hoặc email của người dùng

    // Hàm trả về token hiện tại
    const getToken = () => inMemoryToken;

    // Hàm trả về userId hiện tại
    const getUserId = () => userId;

    // Hàm dừng việc làm mới token
    const abortRefreshToken = () => {
        if (refreshTokenTimeoutId) window.clearTimeout(refreshTokenTimeoutId); // Nếu có timeout thì xóa
    };

    // Hàm xóa token khỏi bộ nhớ và localStorage, đồng thời gửi sự kiện logout
    const deleteToken = () => {
        inMemoryToken = null; // Xóa token khỏi bộ nhớ
        abortRefreshToken(); // Dừng việc làm mới token
        window.localStorage.setItem(LOGOUT_EVENT_NAME, Date.now().toString()); // Ghi nhận thời gian logout vào localStorage
        return true;
    };

    // Lắng nghe sự kiện 'storage' để xóa token trên các tab khác của trình duyệt khi có sự kiện logout
    window.addEventListener('storage', event => {
        if (event.key === LOGOUT_EVENT_NAME)
            inMemoryToken = null; // Xóa token khỏi bộ nhớ nếu có sự kiện logout
    });

    // Hàm đặt token mới vào bộ nhớ và cài đặt thời gian làm mới token
    const setToken = (accessToken) => {
        inMemoryToken = accessToken; // Lưu token vào bộ nhớ
        const decoded = jwtDecoded(accessToken); // Giải mã token
        userId = decoded.userId || decoded.email; // Lấy userId hoặc email từ token đã giải mã
        setRefreshTokenTimeout((decoded.exp - decoded.iat)); // Thiết lập thời gian làm mới token dựa trên thời gian hết hạn
        return true;
    };

    // Hàm gọi API để lấy token mới (refresh token)
    const getRefreshToken = async() => {
        try {
            const response = await fetch(process.env.REACT_APP_URL_WEB_SERVER_ROUTER_REFRESH_TOKEN, {
                credentials: 'include' // Gửi cookie kèm theo yêu cầu
            });
            const data = (await response.json()); // Chuyển kết quả response thành JSON

            setToken(data.accessToken); // Đặt token mới
            return true;
        } catch (error) {
            console.log('UNAUTHENTICATED', error); // Log lỗi nếu không xác thực được
            deleteToken(); // Xóa token nếu có lỗi
            return false;
        }
    };

    // Hàm thiết lập timeout để tự động làm mới token sau một khoảng thời gian
    const setRefreshTokenTimeout = (delay) => {
        refreshTokenTimeoutId = window.setTimeout(getRefreshToken, delay * 1000 - 5000); // Cài đặt thời gian làm mới trước 5 giây khi token hết hạn
    };

    // Trả về các hàm cần thiết để quản lý token
    return {
        getToken,       // Lấy token hiện tại
        setToken,       // Đặt token mới
        getRefreshToken,// Lấy token mới từ API
        deleteToken,    // Xóa token
        getUserId       // Lấy userId từ token
    };
};

export default JWTManager(); // Xuất đối tượng JWTManager để sử dụng trong các phần khác của ứng dụng
