import React from 'react'
import { Link } from 'react-router-dom'

export default function CardSubUser(props) {

    return (
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-8 me-auto mt-3' >
            <div className="row ms-auto mt-1">
                <div className='col-12 d-flex flex-row mt-3'>
                <Link 
                    to={"/user/"+ props.idUser}
                >
                    <img 
                        className='showListUser'
                        src={props.avatarUser} 
                        alt={'ảnh đại diện của người dùng '+props.username}
                    />
                </Link>
                    <div className='d-flex flex-column ms-3 decorationLink'>
                        <Link to={"/user/"+ props.idUser} >
                            <h5 className='nameUserInLink'>{props.username}</h5>
                        </Link>
                        <p className='text_per_line '>{props.about}</p>
                        <div className="d-flex flex-row ">
                            <p className='me-1'>
                                {props.intUserSub}
                            </p>
                            <div>•</div>
                            <p className='ms-1'>
                                Người đăng ký
                            </p>
                        </div>
                    </div>
                </div>      
            </div>
        </div>
                                 
        )
}
