import React,{useState} from 'react'
import { useMutation } from '@apollo/client';
import { PUBLISH_USER } from '../../../../../graphql-client/Mutation';
import { GET_AVATAR_USER_MANAGE } from '../../../../../graphql-client/Queries';
import ComponentUploadVideoToS3 from '../../../../../uploadToS3/ComponentUploadVideoToS3';
export default function FormVideoProfile() {
    const [formState, setFormState] = useState({ url: '', thumbnail:'' });

    const [publishUser] = useMutation(PUBLISH_USER, {
      variables: {
        videoAbout: formState.url,
      },
        update: (cache, { data }) => {
          cache.writeQuery({
            query: GET_AVATAR_USER_MANAGE,
            data: {
              userManage: data.publishUser,
            },
          });
        },
      });
      const handleSubmit = async (e) => {
        e.preventDefault();
        publishUser()
        alert("Đã thay đổi video giới thiệu.");
      }
    return (
        <div className="modal fade" id="toolVideoProfileModal" tabIndex={-1} aria-labelledby="toolVideoProfileModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="toolVideoProfileModalLabel">
                            Chỉnh sửa Video giới thiệu của bạn.
                        </h5>
                    </div>
                    <div className="modal-body">
                    <label className="col-form-label">Tải lên video</label>
                        <ComponentUploadVideoToS3 formState={formState} setFormState={setFormState}></ComponentUploadVideoToS3>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Thoát</button>
                            {formState.url&&<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSubmit} >Cập nhật</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
