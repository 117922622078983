import React from 'react'
import {PUBLISH_USER} from '../../../../../graphql-client/Mutation'
import {GET_AVATAR_USER_MANAGE} from '../../../../../graphql-client/Queries'
import { useState } from 'react'
import { useMutation } from '@apollo/client'

export default function FormFromProfile() {
  
    const [fromFormProfile, setFromFormProfile] = useState({info:''})


    const [creatFromInfoProfile] = useMutation(PUBLISH_USER,
          {
            variables: {
                info: fromFormProfile.info
            },
            update:(cache, {data})=>{
              cache.writeQuery({
                query:GET_AVATAR_USER_MANAGE,
                data:{
                  userManage: data.publishUser
                }
              });
            }
          }
        )

    return (
      <div>
        <button 
            // onClick={handleButtonClick}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            type="button" 
            title='chỉnh sửa'
            className="btn dropdown ms-auto" 
            data-bs-toggle="modal"
            data-bs-target="#toolFromProfileModal" 
        >
          <i className="fas fa-pen" />
        </button>
        { 
          // isOpen === true &&
          <div className="modal fade" id="toolFromProfileModal" tabIndex={-1} aria-labelledby="toolFromProfileModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Chỉnh sửa phần giới thiệu của bạn
                      </h5>
                    </div>
                    <div className="modal-body">
                      <form
                      onSubmit={(e) => {
                          e.preventDefault();
                          creatFromInfoProfile();
                          setFromFormProfile({fromFormProfile, info:''})
                          alert("Đã thay đổi thành công." )
                        }}
                      >
                          <div className="mb-3">
                            <label htmlFor="Channels-address" className="col-form-label">Địa chỉ liên lạc:</label>
                            <textarea 
                              id="Channels-address" 
                              type="text" className="form-control" 
                              rows="4" cols="50" 
                              value={fromFormProfile.info}
                              onChange={(e) =>
                                setFromFormProfile({
                                  ...fromFormProfile,
                                  info: e.target.value
                                })
                              }
                            />
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" >Thoát</button>
                            <button type="submit" className="btn btn-primary close" data-bs-dismiss="modal">Gửi</button>
                          </div>
                      </form>
                    </div>
                </div>
              </div>
          </div>
        }
      </div>
        
    )
}
