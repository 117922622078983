import React,{useState} from 'react';
import { VIDEOS_WATCHED } from '../../graphql-client/Queries';
import { useQuery } from '@apollo/client';
import VideoCard from '../home/home_right/videoCard/VideoCard';
import NotificationError from '../NotificationError';
export default function VideoWatchedRight() {
    const upTakeOnFetchMore = 6;
    const beginTake = 6;
    const [buttonStatus, setButtonStatus] = useState({
      isCountVideoDoClick: beginTake,
      showButtonNewVideo: false,
     })
    const {data, loading, error, fetchMore} = useQuery(VIDEOS_WATCHED,{
        variables:{
            take: beginTake
        }
    })
    if(loading) return <p>loading...</p>
    if(error) return <p>Error! {error.message}</p>

    const onLoadMore = () => {
        fetchMore({
        variables: {
            take: videosWatchedUni.length+upTakeOnFetchMore
        },
      })}

    let videosWatchedUni = new Set();

    const addVideosWatchedUni = () => {
        data.videosWatched.forEach((e) => {
            videosWatchedUni.add(e.video);
        });
        videosWatchedUni = [...videosWatchedUni];
    };
    
    addVideosWatchedUni();
    return (
        <div className="col-xl-10 col-lg-12 col-mb-12 col-sm-12 mx-auto">
            <div className="p-0 marginVideoRecommend">
                <h5 className='ms-3 mt-1'>Video đã xem gần đây</h5>
                {videosWatchedUni.length === 0
                ?<NotificationError text={'Bạn chưa xem Video nào.'}></NotificationError>
                :<div className="row ms-auto">
                    {
                        videosWatchedUni.map((video)=>{
                            return (          
                                <VideoCard
                                    key={video.id}
                                    title ={video.title}
                                    date={video.createdAt}
                                    view={video.countView}
                                    like={video.countLike}
                                    videoId={video.id}
                                    username={video.user.username}
                                    avatarUser={video.user.avatar}
                                    idUser={video.user.id}
                                    thumbnail={video.thumbnail}
                                >
                                </VideoCard>
                            )
                        })
                    }
                </div>
                }
                {
                    videosWatchedUni.length===buttonStatus.isCountVideoDoClick 
                    && 
                    <button className=' btn  ms-2' 
                    onClick={()=>{
                        setButtonStatus({isCountVideoDoClick: buttonStatus.isCountVideoDoClick+upTakeOnFetchMore}) 
                        onLoadMore()
                    }}
                    > 
                        <b>
                            Xem thêm video
                        </b>
                    </button>
                }
            </div>
        </div>
    )
}
